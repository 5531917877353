import { getDDusers } from '@/api/role'
import { getUserInfoByName } from '@/api/login'

const userList = {
  state: {
    ddAllUser: [],
    allUserList: []
  },
  mutations: {
    SET_DDALLUSER: (state, ddAllUser) => {
      state.ddAllUser = ddAllUser
    },
    SET_ALLUSERLIST: (state, allUserList) => {
      state.allUserList = allUserList
    }
  },
  actions: {
    // 获取所有的钉钉用户列表
    GetDdAllUser({ commit, state }) {
      return new Promise((resolve, reject) => {
        // 获取员工 0为全部数据
        getDDusers(0).then(res => {
          if (res.code === '000000') {
            // this.ddAllUser = res.data
            commit('SET_DDALLUSER', res.data)
          } else {
            reject(new Error('SET_DDALLUSER: is Null !'))
          }
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取OA那边的用户列表数据
    GetAllUser({ commit, state }) {
      // /user/get/list
      // 获取公司下的人员参数
      // const params = {
      //   pageSize: 99999,
      //   pageNum: 1,
      //   ucId: id
      // }
      return new Promise((resolve, reject) => {
        getUserInfoByName({ pageNum: 1, pageSize: 10000, status: 1 }).then(res => {
          if (res.code === '000000') {
            commit('SET_ALLUSERLIST', res.data.list.filter(i => i.name))
          } else {
            reject(new Error('SET_ALLUSERLIST: is Null !'))
          }
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default userList
