// 菜单管理请求
import request from '@/utils/request'
// 获取树形菜单
export function userMenu(reqParams) {
  return request({
    url: `/usermenu/get/tree/${reqParams}`,
    method: 'get'
    // params: reqParams
  })
}
// 新增菜单
export function addUserMenu(reqParams) {
  return request({
    url: `/usermenu/add`,
    method: 'post',
    data: reqParams
  })
}
// 批量删除菜单
export function deleteUserMenu(reqParams) {
  return request({
    url: `/usermenu/delete/beach`,
    method: 'delete',
    data: reqParams
  })
}
// 更新菜单
export function editUserMenu(reqParams) {
  return request({
    url: `/usermenu/update`,
    method: 'put',
    data: reqParams
  })
}
// 获取业务系统树形菜单
export function bussinessTree(reqParams) {
  return request({
    url: `/usermenu/get/bussinesstree/${reqParams}`,
    method: 'get'
    // params: reqParams
  })
}
// 获取业务系统树形菜单 - 新
export function bussinessTreeNew(reqParams) {
  return request({
    url: `/usermenu/get/bussinesstree/${reqParams}`,
    method: 'get'
  })
}
// 获取actions的树形结构
export function actionsTreeData(id) {
  return request({
    url: `/usermenu/get/userMenuActionTree/${id}`,
    method: 'get'
  })
}
// 获取菜单权限action树形，带过滤条件
export function actionsTreeDataFilter(params) {
  return request({
    url: `/usermenu/get/userMenuActionTree2`,
    method: 'get',
    params
  })
}
// 绑定主权限
export function bindMainPower(params) {
  return request({
    url: '/userMenuRightBind/editRelation',
    method: 'put',
    data: params
  })
}
