import request from '@/utils/request'

export function getRole(reqParams) {
  return request({
    url: '/role/get/page',
    method: 'GET',
    params: reqParams
  })
}
export function roleAdd(reqParams) {
  return request({
    url: '/role/add',
    method: 'POST',
    data: reqParams
  })
}
export function roleUpdate(reqParams) {
  return request({
    url: '/role/update',
    method: 'PUT',
    data: reqParams
  })
}
export function roleDelete(reqParams) {
  return request({
    url: '/role/delete/' + reqParams,
    method: 'DELETE',
    data: reqParams
  })
}
export function statusUpdate(reqParams) {
  return request({
    url: 'role/update/status',
    method: 'PUT',
    data: reqParams
  })
}
export function getProductLineById(id) {
  return request({
    url: '/role/get/productLine/' + id,
    method: 'GET'
  })
}
export function getProductLine(params) {
  return request({
    url: '/productLine/get/page',
    method: 'GET',
    params: params
  })
}
export function updateRights(reqParams) {
  return request({
    url: '/role/update/rights',
    method: 'PUT',
    data: reqParams
  })
}
export function updateProductLine(reqParams) {
  return request({
    url: '/user/update/productLine',
    method: 'PUT',
    data: reqParams
  })
}
export function getCategory(reqParams) {
  return request({
    url: '/role/get/category/' + reqParams,
    method: 'GET'
  })
}
// 钉钉所有员工
export function getDDusers(id) {
  return request({
    url: `user/get/dingTaikUser/${id}`,
    method: 'get'
  })
}
// 钉钉部门架构
export function getDepartTree() {
  return request({
    // url: '/department/get/1030/tree', // 上正式环境时换这个ID
    url: `/department/get/${process.env.VUE_APP_COMPANY_ID}/tree`,
    method: 'get'
  })
}
// 根据角色ID查询拥有的系统
export function getRoleSystem(params) {
  return request({
    url: '/userSystemRoleMap/getUserSystemByRole',
    method: 'get',
    params: params
  })
}
