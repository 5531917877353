/**
 * Created by jiachenpan on 16/11/18.
 */
import { getPowerInfo } from '@/utils/auth'
import { getDepartTree } from '@/api/role.js'
import { getStyle } from '@/api/style.js'

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

// 格式化时间
export function getQueryObject(url) {
  url = url === null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 *get getByteLen
 * @param {Sting} val input value
 * @returns {number} output value
 */
export function getByteLen(val) {
  let len = 0
  for (let i = 0; i < val.length; i++) {
    if (val[i].match(/[^\x00-\xff]/gi) != null) {
      len += 1
    } else {
      len += 0.5
    }
  }
  return Math.floor(len)
}

export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}'
  )
}

export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

export function objectMerge(target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

export const pickerOptions = [
  {
    text: '今天',
    onClick(picker) {
      const end = new Date()
      const start = new Date(new Date().toDateString())
      end.setTime(start.getTime())
      picker.$emit('pick', [start, end])
    }
  },
  {
    text: '最近一周',
    onClick(picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
      picker.$emit('pick', [start, end])
    }
  },
  {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      picker.$emit('pick', [start, end])
    }
  },
  {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      picker.$emit('pick', [start, end])
    }
  }
]

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}
// 获取本地静态ip
export function getIp() {
  // compatibility for firefox and chrome
  return new Promise((resolve, reject) => {
    var MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection
    var pc = new MyPeerConnection({
      iceServers: []
    })
    var noop = function() { }
    var localIPs = {}
    var ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
    // create a bogus data channel
    pc.createDataChannel('')
    // create offer and set local description
    pc.createOffer().then(function(sdp) {
      sdp.sdp.split('\n').forEach(function(line) {
        // if (line.indexOf('candidate') < 0) return
        line.match(ipRegex).forEach(iterateIP)
      })
      pc.setLocalDescription(sdp, noop, noop)
    }).catch(function(reason) {
      // An error occurred, so handle the failure to connect
    })
    // sten for candidate events
    pc.onicecandidate = function(ice) {
      if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return
      ice.candidate.candidate.match(ipRegex).forEach(iterateIP)
    }
    function iterateIP(ip) {
      if (!localIPs[ip]) {
        resolve(ip)
      } else {
        reject(new Error('getIp: not get !'))
      };
      localIPs[ip] = true
    }
  })
}

export function getUrlKey(keyname) {
  return decodeURIComponent((new RegExp('[?|&]' + keyname + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [])[1].replace(/\+/g, '%20')) || null
}

// 处理 公司，部门级联数据
// 通过递归数据的父id，然后把数组第一个元素删除，把目标id push进数组，得到的就是需要的级联id
export function getCascaderData(data, id, listType) {
  const parentList = new Object()
  function buildParentList(arr, listType) {
    arr.forEach(item => {
      switch (listType) {
        case 'company':
          if (item.ucId !== undefined && item.ucId !== null) {
            const pid = item.ucParentId
            const oid = item.ucId
            parentList[oid] = pid
          }
          if (item.childrenCompanyList !== undefined && item.childrenCompanyList !== null) {
            buildParentList(item.childrenCompanyList, listType)
          }
          break
        case 'department':
          if (item.udId !== undefined && item.udId !== null) {
            const pid = item.udSupervisorId
            const oid = item.udId
            parentList[oid] = pid
          }
          if (item.childrenDepartmentList !== undefined && item.childrenDepartmentList !== null) {
            buildParentList(item.childrenDepartmentList, listType)
          }
          break
        case 'position':
          if (item.upId !== undefined && item.upId !== null) {
            const pid = item.parentId
            const oid = item.upId
            parentList[oid] = pid
          }
          if (item.childrenPositionList !== undefined && item.childrenPositionList !== null) {
            buildParentList(item.childrenPositionList, listType)
          }
          break
      }
    })
  }
  const arr = new Array()
  function findParent(idx) {
    if (parentList[idx] !== undefined && parentList[idx] !== null) {
      const pid = parentList[idx]
      findParent(pid)
      arr.push(pid)
      return arr
    }
  }

  buildParentList(data, listType)
  let list = findParent(id)
  // 处理脏数据，防止报错。当找不到这个id时赋空值
  if (list) {
    list.shift() // 删除数组第一个元素，即最开始那个父元素的id
    list.push(id)
  } else {
    list = []
  }
  return list
}
// 清除空的查询参数
export function cleanParams(params) {
  if (params && typeof params === 'object') {
    const json = JSON.parse(JSON.stringify(params))
    Object.keys(json).forEach(keys => {
      if (json[keys] === undefined || json[keys] === null || json[keys] === '') {
        delete json[keys]
      }
    })
    return json
  }
  return {}
}

// 处理角色数组和级联结构不对应 需要遍历得到上级id 现在只有两层 可优化为递归
export function roleChosedReset(systemRoleIds, roleTree) {
  const sysRoleIds = []
  for (let index = 0; index < roleTree.length; index++) {
    const element = roleTree[index]
    if (systemRoleIds.includes(element.roleId)) {
      sysRoleIds.push([element.roleId])
    }
    if (element.supRoleList) {
      for (let childIndex = 0; childIndex < element.supRoleList.length; childIndex++) {
        const elementChild = element.supRoleList[childIndex]
        if (systemRoleIds.includes(elementChild.roleId)) {
          sysRoleIds.push([elementChild.supRoleId, elementChild.roleId])
        }
      }
    }
  }
  // console.log(888,sysRoleIds)
  return sysRoleIds
}

// 获取公司个人或部门数据面板配置，有则跳转面板没有则旧系统用户中心
export function toLink() {
  // 跳转看板在点击登录和扫码登录都需要判断，抽调成公共的
  const info = getPowerInfo()
  // 获取公司架构，查询用户部门是否配置看板，如果有跳看板页面，没有跳旧用户中心
  return new Promise(function(resolve, reject) {
    getDepartTree()
      .then((res) => {
        const data = res.data
        if (data.length > 0) {
          // 查询 用户 树形部门 通过多个部门 如果风格都设置了 取最接近部门的风格
          const newLevel = getParent(
            data,
            info.userInfo.departmentId,
            info.userInfo.userId
          )
          const params = {
            userId: newLevel
          }
          getStyle(params)
            .then((res) => {
              if (res.data.length > 0) {
              // this.$router.push({ path: '/userCenterNew' })
                resolve('/userCenterNew')
              } else {
                resolve('/')
              }
            })
            .catch(() => {
            // this.$router.push({ path: '/' })
              resolve('/')
            })
        } else {
          resolve('/')
        }
      })
      .catch(() => {
        resolve('/')
      })
  })
}

export function getParent(data, id, userId) {
  let idStr = ''
  function find(data, id) {
    data.forEach((item) => {
      if (item.udId === id) {
        idStr = item.longId
      } else if (item.children) {
        find(item.children, id)
      }
    })
  }
  find(data, id)
  const ids = idStr.split('-')
  ids.push(userId)
  return ids.filter((i) => i !== '').join(',')
}
export const getCurrentTime = () => {
  return (
    (new Date().getHours() >= 10
      ? new Date().getHours()
      : "0" + new Date().getHours()) +
    ":" +
    (new Date().getMinutes() >= 10
      ? new Date().getMinutes()
      : "0" + new Date().getMinutes()) +
    ":" +
    (new Date().getSeconds() >= 10
      ? new Date().getSeconds()
      : "0" + new Date().getSeconds())
  );
};
// 导出excel
export function exportExcel(data, fileName) {
  const blob = new Blob([data], {
    type: "application/vnd.ms-excel;charset=utf-8"
  });
  const downloadElement = document.createElement("a");
  const href = window.URL.createObjectURL(blob); //创建下载的链接
  downloadElement.href = href;
  downloadElement.download = fileName + ".xls"; //下载后文件名
  document.body.appendChild(downloadElement);
  downloadElement.click(); //点击下载
  document.body.removeChild(downloadElement); //下载完成移除元素
  window.URL.revokeObjectURL(href); //释放掉blob对象
}