import { newlogin, ddCodeLogin,qrLogin } from '@/api/special-login'
import { logout, getUserInfo } from '@/api/login'
import { getToken, setToken, setUserToken, removeToken, setTokenPower, removeTokenPower, setPowerInfo, getPowerInfo, removePowerInfo } from '@/utils/auth'
import { removeSystemToken } from '@/utils/systemid'
import { getUrlKey } from '@/utils/index'

const Base64 = require('js-base64').Base64
const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    actionUrl: [],
    permissions: [], // 权限tags列表
    permissionTag: false,
    isDDlogin: false,
    account:{
      username: '',
      password: '',
      code:''
    }
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_ACTION_URL: (state, actionUrl) => {
      state.actionUrl = actionUrl
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_PERMISSIONS_TAG: (state, value) => {
      state.permissionTag = value
    },
    SET_ISDDLOGIN: (state, value) => {
      state.isDDlogin = value
    },
    SET_ACCOUNT: (state, value) => {
      state.account = value
    }
  },
  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        newlogin(username, userInfo.password).then(response => {
          if(['0000013','0000014'].includes(response.code)){
            resolve(response)
            commit('SET_ACCOUNT', {username, password: userInfo.password,code:response.code})
            return
          }
          setToken(response.data.token)
          setTokenPower(JSON.stringify(response.data.token))
          setUserToken(response.data.userInfo.username)
          commit('SET_TOKEN', response.data.token)
          if (response.data.userInfo) {
            // 把登录信息存起来，防止用户手动刷新之后state丢失
            const info = JSON.stringify(response.data)
            setPowerInfo(info)
            // sessionStorage.setItem('Power-Admin-Info', info)
            // sessionStorage.setItem('userId', info.userId)
          } else {
            reject(new Error('loginInfo: is Null !'))
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 验证码登录
    qrLogin({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        qrLogin(userInfo).then(response => {
          if(response.code === '000000'){
            setToken(response.data.token)
            setTokenPower(JSON.stringify(response.data.token))
            setUserToken(response.data.userInfo.username)
            commit('SET_TOKEN', response.data.token)
            if (response.data.userInfo) {
              // 把登录信息存起来，防止用户手动刷新之后state丢失
              const info = JSON.stringify(response.data)
              setPowerInfo(info)
              // sessionStorage.setItem('Power-Admin-Info', info)
              // sessionStorage.setItem('userId', info.userId)
            } else {
              reject(new Error('loginInfo: is Null !'))
            }
            resolve(response)
          }
          if(response.code === '-1'){
            this.$message.error('系统出错，请联系管理员')
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 钉钉扫码登录
    DingLogin({ commit }, code) {
      return new Promise((resolve, reject) => {
        ddCodeLogin({ dingCode: code }).then(response => {
          setToken(response.data.token)
          setUserToken(response.data.userInfo.username)
          commit('SET_TOKEN', response.data.token)
          commit('SET_ISDDLOGIN', true) // 钉钉登录过来的密码都不过期，为了不跳重置密码
          if (response.data.userInfo) {
            // 把登录信息存起来，防止用户手动刷新之后state丢失
            const info = JSON.stringify(response.data)

            setPowerInfo(info)
            // sessionStorage.setItem('Power-Admin-Info', info)
            // sessionStorage.setItem('userId', info.userId)
          } else {
            reject(new Error('loginInfo: is Null !'))
          }
          resolve(response)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 存储从钉钉工作台跳转进来的token和userInfo
    saveUserInfo({ commit }, data) {
      setToken(data.token)
      setUserToken(data.userInfo.username)
      commit('SET_TOKEN', data.token)
      // sessionStorage.setItem('Power-Admin-Info', JSON.stringify(data))
      setPowerInfo(JSON.stringify(data))
    },
    // 获取用户信息
    GetUserInfo({ commit, state }) {
      // 因为没有通过token获取用户信息接口，先这么做
      // return new Promise((resolve, reject) => {
      //   const roles = JSON.parse(sessionStorage.getItem('Power-Admin-Info'))
      //   if (roles) {
      //     commit('SET_ROLES', roles)
      //     commit('SET_NAME', roles.userInfo.name)
      //     resolve()
      //   } else {
      //     reject(1)
      //     console.log('getInfo: roles must be a non-null array !')
      //   }
      // })

      return new Promise((resolve, reject) => {
        // const roles = JSON.parse(sessionStorage.getItem('Power-Admin-Info'))
        const roles = JSON.parse(JSON.stringify(getPowerInfo()))
        // 每次请求actions接口时，将全局的标识字段置为false,以控制v-permission延时创建
        commit('SET_PERMISSIONS_TAG', false)

        // 之前没有去请求这个接口，现在加上
        getUserInfo().then(response => {
          if (!response.data) {
            reject(new Error('Verification failed, please login again.'))
          }
          const data = response.data
          commit('SET_ACTION_URL', data.actionUrl)
          const permission = response.data.userRightVoList.filter(item => item.tag)
          commit('SET_PERMISSIONS', permission) // 账号拥有的权限tag列表
          commit('SET_PERMISSIONS_TAG', true)
        }).catch(error => {

        })
        if (roles) {
          commit('SET_ROLES', roles)
          commit('SET_NAME', roles.userInfo.name)
          resolve()
        } else {
          reject(new Error('1'))
          console.log('getInfo: roles must be a non-null array !')
        }
      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          removePowerInfo()
          // sessionStorage.removeItem('Power-Admin-Info')
          // sessionStorage.removeItem('userId')
          removeToken('Power-Admin-Token')
          removeToken('Power-User-Work')
          removeSystemToken()
          removeTokenPower()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit, state }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removePowerInfo()
        // sessionStorage.removeItem('Power-Admin-Info')
        // sessionStorage.removeItem('userId')
        // removeToken()
        removeToken('Power-Admin-Token')
        removeToken('Power-User-Work')
        removeTokenPower()
        removeSystemToken()
        resolve()
      })
    },

    // 获取URL信息
    LoginByUsername({ commit }) {
      const urlInfo = JSON.parse(Base64.decode(getUrlKey('code')))
      return new Promise((resolve, reject) => {
        if (urlInfo) {
          setToken(urlInfo.token)
          setTokenPower(JSON.stringify(urlInfo))
          // sessionStorage.setItem('urlInfo', JSON.stringify(urlInfo))
          setPowerInfo(JSON.stringify({ userInfo: urlInfo.userInfo }))
          resolve(urlInfo)
        } else {
          reject(new Error('No request to URL parameters'))
        }
      })
    }
  }
}

export default user
