<template>
  <div v-if="showTags" class="tags">
    <ul>
      <li v-for="(tag,index) in tagsList" :key="index" :class="{'active':isActive(tag.path)}" class="tags-li">
        <router-link :to="tag.path" class="tags-li-title">
          {{ language === 'zh' ? tag.nameZh : tag.name }}
        </router-link>
        <span class="tasg-li-icon" @click="closeTags(index)"><i class="el-icon-close"/></span>
      </li>
    </ul>
  </div>
</template>
<script>
import store from '../../../../store'
export default {
  data() {
    return {
      tagsList: []
    }
  },
  computed: {
    showTags() {
      return this.tagsList.length > 0
    },
    language() {
      return store.getters.language
    }
  },
  watch: {
    $route(newValue, oldValue) {
      this.setTags(newValue)
    }
  },
  created() {
    this.setTags(this.$route)
  },
  methods: {
    isActive(path) {
      return path === this.$route.fullPath.split('?')[0]
    },
    setTags(route) {
      const noParamsURL = route.fullPath.split('?')
      const isExist = this.tagsList.some(item => {
        return item.path === noParamsURL[0]
      })
      if (!isExist) {
        // 最多显示8个
        if (this.tagsList.length >= 8) {
          this.tagsList.shift()
        }
        let nameZh = ''
        store.getters.tagRouters.forEach(item => {
          if (item.path === noParamsURL[0]) {
            nameZh = item.nameZh
          }
        })
        this.tagsList.push({
          name: route.name,
          nameZh: nameZh,
          path: noParamsURL[0]
        })
      }
      // 更新tagsList
      store.dispatch('SET_TAGS', this.tagsList)
    },
    closeTags(index) {
      const delItem = this.tagsList.splice(index, 1)[0]
      const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1]
      if (item) {
        delItem.path === this.$route.fullPath && this.$router.push(item.path)
      } else {
        this.$router.push('/')
      }
      store.dispatch('SET_TAGS', this.tagsList)
    }
  }
}
</script>
<style>
    .tags {
        position: relative;
        height: 30px;
        overflow: hidden;
        background: #f0f0f0;
        padding-right: 120px;
        box-shadow: 0 5px 10px #ddd;
    }

    .tags ul {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .tags-li {
        float: left;
        margin: 3px 5px 2px 3px;
        border-radius: 3px;
        font-size: 12px;
        overflow: hidden;
        cursor: pointer;
        height: 23px;
        line-height: 23px;
        border: 1px solid #e9eaec;
        background: #fff;
        padding: 0 5px 0 12px;
        vertical-align: middle;
        color: #666;
        -webkit-transition: all .3s ease-in;
        -moz-transition: all .3s ease-in;
        transition: all .3s ease-in;
    }

    .tags-li:not(.active):hover {
        background: #f8f8f8;
    }

    .tags-li.active {
        color: #fff;
        border: 1px solid #409EFF;
        background-color: #409EFF;
    }

    .tags-li-title {
        float: left;
        max-width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 5px;
        color: #666;
    }

    .tags-li.active .tags-li-title {
        color: #fff;
    }

</style>
