import request from '@/utils/request'

export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 验证用户是否第一次登陆 /user/get/check-user-login
export function checkFirst() {
  return request({
    url: '/user/get/check-user-login',
    method: 'get'
  })
}

// 判断用户名是否存在
export function validateUser(reqParams) {
  return request({
    url: '/user/check-user',
    method: 'post',
    data: reqParams
  })
}
// 模糊查询 根据用户名获取用户列表
export function getUserInfoByName(params) {
  return request({
    url: '/user/get/list',
    method: 'get',
    params: params
  })
}
// 模糊查询 根据用户信息和actionUrl
export function getUserInfo() {
  return request({
    url: '/user/get/getuserAndAction',
    method: 'get'
  })
}
// 根据用户id数组，获取用户信息
export function getUserInfoByIdList(params) {
  return request({
    url: `/user/get/users`,
    method: 'post',
    data: {
      noDeleted: true,
      ...params
    }
  })
}
// 获取本机ip
export function getLocalIp() {
  return request({
    url: process.env.VUE_APP_GET_IP,
    method: 'get'
  })
}

