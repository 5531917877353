import { Message, MessageBox } from 'element-ui'
import store from '../store'
import Cookies from 'js-cookie'
import { getToken } from '@/utils/auth'
// import { getSystemToken } from '@/utils/systemid'
const axios = require('axios')

// 创建axios实例
const service = axios.create({
  baseURL: `${process.env.VUE_APP_STYLE_API}`,
  timeout: 500000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    let systemCode
    if (store.getters.token) {
      systemCode = process.env.VUE_APP_SYSTEM_ID_STYLE
      config.headers.Authorization = `Bearer ${getToken()}` // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if (systemCode) {
      config.headers.systemCode = systemCode
    }
    config.headers.sfcLang = store.getters.language
    if (store.getters.userip) {
      // 获取用户ipv4地址发送给后台做验证
      config.headers.hostIp = store.getters.userip
    } else {
      // 获取用户ipv4地址发送给后台做验证
      config.headers.hostIp = Cookies.get('userIp')
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非'000000'是抛错
     */
    const res = response.data
    if (res.code === '031705') {
      return response.data
    } else if (res.code !== '000000') {
      // 401:token过期或者校验不通过; 403:token校验成功,但是无权限访问;
      if (res.code === 401 || res.code === 403 || res.code === '010003' || res.code === '000008' || res.code === '000005') {
        MessageBox.confirm(
          '你已被登出，可以取消继续留在该页面，或者重新登录',
          '确定登出',
          {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          store.dispatch('FedLogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      } else {
        Message({
          message: res.message,
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(new Error('error'))
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
