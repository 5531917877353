import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../views/layout/Layout'

Vue.use(Router)

/* Layout */
// import Router from 'vue-router'

// const Router = require('vue-router')

/**
* hidden: true                   如果 `hidden:true` 则不会在侧边栏sidebar中显示 (default is false)
* alwaysShow: true               如果设置这个为true 则始终显示根菜单，无论它子路由的长度是多少都不显示
*                                如果没设置alwaysShow这个属性，则子级下只能有一个以上的路由，它就会成为嵌套模式，不然就不显示根菜单

* redirect: noredirect           如果这样设置 `redirect:noredirect` 面包屑breadcrumb中不会重定向
* name:'router-name'             name这个属性由 <keep-alive> 使用  (必须设置！！！)
* meta : {
    title: 'title'               子菜单和面包屑中显示的名称
    icon: 'svg-name'             图标显示在侧边栏sidebar中
    breadcrumb: false            如果为false，则该项将隐藏在breadcrumb中(default is true)
  }
**/
export const constantRouterMap = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/verificationCode',
    name: 'verificationCode',
    component: () => import('@/views/login/verificationCode'),
    hidden: true
  },
  // 钉钉工作台中转页，通过路由携带用户信息及token
  {
    path: '/transfer',
    name: 'transfer',
    component: () => import('@/views/homepage/index'),
    hidden: true
  },
  {
    path: '/',
    component: () => import('@/views/userCenter/index0'),
    hidden: true
  },
  {
    path: '/userCenterNew',
    component: () => import('@/views/userCenter/index'),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/404'], resolve),
    hidden: true
  },
  // 修改密码
  {
    path: '/resetPassword',
    component: (resolve) => require(['@/views/resetPassword/inedx.vue'], resolve),
    hidden: true
  },
  // 找回密码
  {
    path: '/findPassword',
    name: 'findPassword',
    component: (resolve) => require(['@/views/findPassword/index.vue'], resolve),
    hidden: true
  },
  {
    path: '/homepage',
    component: Layout,
    redirect: '/homepage/index',
    children: [{
      path: '/homepage/index',
      name: 'homepage',
      nameZh: '首页',
      component: (resolve) => require(['@/views/homepage/index'], resolve),
      meta: {
        title: 'index',
        icon: 'index'
      }
    }]
  }
  // {
  //   path: '/user',
  //   component: Layout,
  //   redirect: '/account/index',
  //   alwaysShow: true,
  //   meta: {
  //     title: '用户管理',
  //     icon: 'system-info'
  //   },
  //   children: [
  //     {
  //       path: '/account/index',
  //       component: () => import('@/views/userManage/account/index'),
  //       name: 'account',
  //       nameZh: '账号管理',
  //       meta: {
  //         title: '账号管理',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/systemRole/index',
  //       component: () => import('@/views/userManage/systemRole/index'),
  //       name: 'role',
  //       nameZh: '角色管理',
  //       meta: {
  //         title: '角色管理',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/site/index',
  //       component: () => import('@/views/userManage/site/index'),
  //       name: 'site',
  //       nameZh: '站点配置',
  //       meta: {
  //         title: '站点配置',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/productConf',
  //       component: () => import('@/views/userManage/product/index'),
  //       name: 'product',
  //       nameZh: '产品线配置',
  //       meta: {
  //         title: '产品线配置',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/userAuthorization',
  //       component: () => import('@/views/userManage/userAuthorization/index'),
  //       name: 'userAuthorization',
  //       nameZh: '权限分配',
  //       meta: {
  //         title: '权限分配',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/operateLogs',
  //       component: () => import('@/views/userManage/logs/index'),
  //       name: 'operateLogs',
  //       nameZh: '操作日志',
  //       meta: {
  //         title: '操作日志',
  //         icon: 'system-info'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/company',
  //   component: Layout,
  //   // redirect: '/company/index',
  //   alwaysShow: true,
  //   meta: {
  //     title: '公司管理',
  //     icon: 'system-info'
  //   },
  //   children: [
  //     {
  //       path: '/company/index',
  //       component: () => import('@/views/companyManage/company/index'),
  //       name: 'company',
  //       nameZh: '公司管理',
  //       meta: {
  //         title: '公司管理',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/department/index',
  //       component: () => import('@/views/companyManage/department/index'),
  //       name: 'department',
  //       nameZh: '部门管理',
  //       meta: {
  //         title: '部门管理',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/post/index',
  //       component: () => import('@/views/companyManage/post/index'),
  //       name: 'post',
  //       nameZh: '岗位管理',
  //       meta: {
  //         title: '岗位管理',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/position/index',
  //       component: () => import('@/views/companyManage/position/index'),
  //       name: 'position',
  //       nameZh: '职位管理',
  //       meta: {
  //         title: '职位管理',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/postConf/index',
  //       component: () => import('@/views/companyManage/postConf/index'),
  //       name: 'postConf',
  //       nameZh: '入职岗位配置',
  //       meta: {
  //         title: '入职岗位配置',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/postConfHr/index',
  //       component: () => import('@/views/companyManage/postConfHr/index'),
  //       name: 'postConfHr',
  //       nameZh: '人事岗位配置',
  //       meta: {
  //         title: '人事岗位配置',
  //         icon: 'system-info'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/system',
  //   component: Layout,
  //   redirect: '/system/index',
  //   alwaysShow: true,
  //   meta: {
  //     title: '系统管理',
  //     icon: 'system-info'
  //   },
  //   children: [
  //     {
  //       path: '/system/index',
  //       component: () => import('@/views/systemManage/systemMenu/index'),
  //       name: 'systemMenu',
  //       nameZh: '系统菜单配置',
  //       meta: {
  //         title: '系统菜单配置',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/actions/index',
  //       component: () => import('@/views/systemManage/actions/index'),
  //       name: 'actions',
  //       nameZh: '权限 Actions',
  //       meta: {
  //         title: '权限 Actions',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/customDepartment/index',
  //       component: () => import('@/views/systemManage/customDepartment/index'),
  //       name: 'customDepartment',
  //       nameZh: '自定义部门',
  //       meta: {
  //         title: '自定义部门',
  //         icon: 'system-info'
  //       }
  //     },
  //     {
  //       path: '/logs/index',
  //       component: () => import('@/views/systemManage/logManage/index'),
  //       name: 'logManage',
  //       nameZh: '系统操作日志',
  //       meta: {
  //         title: '系统操作日志',
  //         icon: 'system-info'
  //       }
  //     }
  //   ]
  // }
]

export default new Router({
  mode: 'history', // 后端支持可开
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})
// 上面是写死的路由  ↑

// 下面是动态路由    ↓

export const asyncRouterMap = [
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

export const routerMap = {
  // 用户管理
  account: (resolve) => require(['@/views/userManage/account/index'], resolve),
  role: (resolve) => require(['@/views/userManage/systemRole/index'], resolve),
  platformManagement: (resolve) => require(['@/views/userManage/site/index'], resolve),
  roleManagement: (resolve) => require(['@/views/userManage/product/index'], resolve),
  userAuthorization: (resolve) => require(['@/views/userManage/userAuthorization/index'], resolve),
  operateLogs: (resolve) => require(['@/views/userManage/logs/index'], resolve),
  accountDataPermission: (resolve) => require(['@/views/userManage/accountDataPermission/index'], resolve),
  // 公司管理
  companies: (resolve) => require(['@/views/companyManage/company/index'], resolve),
  departmentalList: (resolve) => require(['@/views/companyManage/department/index'], resolve),
  post: (resolve) => require(['@/views/companyManage/post/index'], resolve),
  positionLevel: (resolve) => require(['@/views/companyManage/position/index'], resolve),

  // 系统管理
  menu: (resolve) => require(['@/views/systemManage/systemMenu/index'], resolve),
  privilegeManage: (resolve) => require(['@/views/systemManage/actions/index'], resolve),
  userLog: (resolve) => require(['@/views/systemManage/logManage/index'], resolve),

  // OA流程管理
  customDepartment: (resolve) => require(['@/views/systemManage/customDepartment/index'], resolve),
  configureJobs: (resolve) => require(['@/views/companyManage/postConf/index'], resolve),
  configureJobsByHr: (resolve) => require(['@/views/companyManage/postConfHr/index'], resolve)
}

