<template>
    <div>
        <el-dialog :close-on-click-modal="false" :title="title" :visible="dialogVisible" width="30%" @close="$emit('close')">
        <section>
            <el-upload class="upload-demo" ref="upload" action="" :on-remove="handleRemove" :on-change="beforeUpload"
                :file-list="fileList" :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx,.csv文件，最多上传1个文件</div>
            </el-upload>
            <el-button type="text" @click="downloadTemp">下载导入模板</el-button>
        </section>

        <span slot="footer" class="dialog-footer">
            <el-button @click="$emit('close')">取 消</el-button>
            <el-button type="primary" @click="confirm" :loading="loading">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog @close="showErrorTip = false" :close-on-click-modal="false" title="导入错误" :visible="showErrorTip" width="30%">
      <div class="tip-wrap">
        <div v-for="(i,index) in tips" :key="index">
         第{{ i.indexList.join(',') }}行数据导入失败，失败原因：{{ i.tips }}
        </div>
      </div>
    </el-dialog>
    </div>
   
</template>
<script>
import { exportExcel } from '@/utils/index'
import { importSystemMenuTemplate, importSystemMenu } from '@/api/privilegeImport'
export default {
    name: "ImportFile",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'system'
        },
        title: {
            type: String,
            default: '系统菜单配置导入'
        },
        activeId: {
            type: [Number, String],
            required: false,
            default: 0
        }
    },
    data() {
        return {
            fileList: [],
            loading: false,
            showErrorTip: false,
            tips: []
        };
    },
    watch: {
        dialogVisible(val) {
            if (!val) {
                this.fileList = [];
            }
        }
    },
    methods: {
        handleRemove(file, fileList) {
            this.fileList = fileList;
        },
        async confirm() {
            try {
                if (this.fileList.length === 0) {
                    this.$message.error('请先上传文件!');
                    return;
                }
                this.loading = true;
                const formData = new FormData();
                formData.append('file', this.fileList[0].raw);
                const apiMap = {
                    system: `/userSystem/import`,
                    item: `/userSystem/import`,
                    sysActions: `/userRight/import`,
                    Actions: `/action/import`
                }
                let query = {}
                if (['item', 'system'].includes(this.type)) {
                    query = this.type === 'system' ? {} : { usId: this.activeId }
                }else{
                    query = this.activeId ? { usId: this.activeId } : {}
                }
                const res = await importSystemMenu(apiMap[this.type], query, formData);
                this.loading = false;
                if(res.code === '-1'){
                    this.showErrorTip = true
                    this.tips = res.data
                    return
                }
                
                this.$emit('import-success')
            } catch (error) {
                console.error(error);
                this.loading = false
            }

        },
        beforeUpload(file, fileList) {
            // 只能上传.xls,.xlsx,.csv文件, 判断文件后缀
            const isExcel = file.name.endsWith('.xls') || file.name.endsWith('.xlsx') || file.name.endsWith('.csv');
            if (!isExcel) {
                this.$message.error('上传文件只能是.xls,.xlsx,.csv格式!');
                this.fileList = []
                return false;
            }
            // 限制上传文件数量
            if (fileList.length > 1) {
                this.$message.error('只能上传1个文件!');
                return false;
            }
            this.fileList = fileList;
        },
        async downloadTemp() {
            try {
                const apiMap = {
                    system: `/userSystem/get/importTemplate/0`,
                    item: `/userSystem/get/importTemplate/1`,
                    sysActions: `/userRight/get/importTemplate/0`,
                    Actions: `/userRight/get/importTemplate/1`
                }
                const res = await importSystemMenuTemplate(apiMap[this.type])
                exportExcel(res.data, `${this.title}导入模板`)
            } catch (error) {
                console.error(error);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.tip-wrap{
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    div{
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
    }
}
</style>