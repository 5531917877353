// URL设置请求
import request from "@/utils/request";

// 系统菜单配置--导出
export function exportSystemMenu(params) {
  return request({
    url: "/userSystem/export",
    method: "get",
    responseType: "blob",
    params
  });
}
// 系统菜单导入模板
export function importSystemMenuTemplate(url) {
  return request({
    url,
    method: "get",
    responseType: "blob"
  });
}
// 系统菜单配置--导入
export function importSystemMenu(url,query,data) {
  return request({
    url,
    method: "post",
    data,
    params: query
  });
}

/* 
   权限 导出
*/
export function exportActionsApi(params) {
  return request({
    url: "/userRight/export",
    method: "get",
    responseType: "blob",
    params
  });
}
// actions 导出文件
export function exportActionsFile(params) {
  return request({
    url:`/action/export`,
    method: "get",
    responseType: "blob",
    params
  });
}