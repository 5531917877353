<template>
  <div id="app">
    <router-view/>
    <template class="emailreply" tag>
      <emailReply v-if="!isIstore"/>
    </template>
  </div>
</template>

<script>
import emailReply from '@/components/emailReply/index.vue'
export default {
  name: 'App',
  components: { emailReply },
  computed: {
    isIstore() {
      return window.location.host.indexOf('szecommerce.com') > -1 || window.location.host.indexOf('suntekcorps.cn') > -1
    }
  }
}
</script>
