
import Cookies from 'js-cookie'
// 原值
const TokenKey = 'Power-Admin-Token'
// const domainStr = process.env.NODE_ENV === 'production' ? '.suntekcorps.com' : window.location.hostname 10.10.40.150
const domainStr = process.env.NODE_ENV === 'production' ? '.suntekcorps.com' : '.sfccorps.com' // http://account.sfccorps.com/
// let domainStr = window.location.hostname
// if (process.env.NODE_ENV === 'production') {
//   domainStr = '.' + window.location.hostname.split('.').slice(-2).join('.')
// }
// const domainStr = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(-2).join('.') : window.location.hostname
console.log('domain', domainStr, process.env.NODE_ENV)
export function getToken() {
  return Cookies.get(TokenKey, { domain: domainStr })
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { domain: domainStr })
}

export function setUserToken(id) {
  return Cookies.set('Power-User-Work', id, { domain: domainStr })
}

export function removeToken(key) {
  return Cookies.remove(key, { domain: domainStr })
}

// 权限系统完整token，用于处理通过链接进入系统页面时，sessionStorage中没有userInfo信息
const powerToken = window.location.host

export function getTokenPower() {
  return Cookies.get(powerToken)
}

export function setTokenPower(token) {
  return Cookies.set(powerToken, token)
}

export function removeTokenPower() {
  return Cookies.remove(powerToken)
}

const powerInfo = 'Power-Admin-Info'
// login获取的完整信息（原先存储于sessionStorage，现在改为存cookies）
export function setPowerInfo(info) {
  const json = info ? JSON.parse(info) : {}
  return Cookies.set(powerInfo, info ? JSON.stringify(Object.assign(json, {
    userId: json.userInfo.userId,
    username: json.userInfo.username
  })) : '', { domain: domainStr })
}
export function getPowerInfo() {
  return JSON.parse(Cookies.get(powerInfo, { domain: domainStr }))
}
export function removePowerInfo() {
  return Cookies.remove(powerInfo, { domain: domainStr })
}
