
// 用户中心风格获取
import request from '@/utils/styleRequest.js'

//  管理员重置密码
export function getStyle(reqParams) {
  return request({
    url: `/account/selectTemplateDetail`,
    method: 'post',
    data: reqParams
  })
}
