<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
        <span
          v-if="item.redirect==='noredirect'||index===levelList.length-1"
          class="no-redirect"
        >{{ generateTitle(item.meta.title) }}</span>
        <!-- <a v-else @click.prevent="handleLink(item)">{{ generateTitle(item.meta.title) }}</a> -->
        <span v-else>{{ generateTitle(item.meta.title) }}</span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { generateTitle } from '@/utils/i18n'
import pathToRegexp from 'path-to-regexp'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      levelList: null
    }
  },
  computed: {
    ...mapGetters([
      'permission_routers'
    ])
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        // console.log('深度监听',newVal)
        this.getBreadcrumb(0)
      },
      deep: true
    }
    // permission_routers:{
    //   handler(newVal,oldVal){
    //     console.log('动态路由监听',newVal)
    //     this.getBreadcrumb(1)
    //   },
    //   deep:true
    // }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    generateTitle,
    getBreadcrumb(val) {
      // if(val===0){
      //   console.log('route',this.$route)
      // }else{
      //   console.log('route',this.$route)
      //   console.log('permission',this.permission_routers)
      // }

      let matched = this.$route.matched.filter(item => item.meta.title)

      const first = matched[0]
      if (first && first.name !== 'homepage') {
        matched = [{ path: '/homepage/index', meta: { title: '' }}].concat(matched)
      }

      this.levelList = matched.filter(
        item => item.meta && item.meta.title && item.meta.breadcrumb !== false
      )
    },
    pathCompile(path) {
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
