import request from '@/utils/request'

export function newlogin(username, password) {
  return request({
    url: '/login',
    method: 'post',
    data: {
      username: username,
      password: password
    }
  })
}

// 验证用户名是否存在与新老用户
export function validateUser(reqParams) {
  return request({
    url: '/user/check-user',
    method: 'post',
    data: reqParams
  })
}

// 钉钉二维码登录
export function ddCodeLogin(params) {
  return request({
    url: '/code/login',
    method: 'post',
    data: params
  })
}

// 验证码登录
export function qrLogin(params) {
  return request({
    url: '/login',
    method: 'post',
    data: params
  })
}
// 获取二维码
export function getQr(params) {
  return request({
    url: '/googleAuthBindingQr',
    method: 'get',
    params
  })
}
