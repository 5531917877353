<template>
      <div
      id="goodRobot"
      @click="toChatgpt"
      style="position:fixed;z-index:1000;right:20px;top:500px;"
    >
      <div class="imgicon" >
        <img src="./4b807f671fdf1e3cfe97.jpg" style="width:80px;height:80px" />
        <!-- <p>智能回复</p> -->
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      domMoved:false
    }
  },
  mounted() {
     const a = document.getElementById('goodRobot') 
     let drag = false
     const that = this
     a.onmousedown = function(e) {
       drag = true
       console.log(drag, e)
       that.domMoved = false
       document.onmousemove = function(e2) {
         if (drag) {
           a.style.left = `${e2.clientX - 30}px`
           a.style.top = `${e2.clientY - 30}px`
           setTimeout(() => {
             that.domMoved = true
           }, 300)
         }
       }
       // document.onmouseup = function(){
       //   console.log(1111)
       //   document.onmousemove = null
       //   document.onmouseup = null
       //   drag = false
       // }
     }
     a.onmouseup = function() {
       console.log('mouseup', drag, that.domMoved)
       drag = false
       setTimeout(() => {
         that.domMoved = false
       }, 10)
     }
   },
   methods:{
    toChatgpt() {
      if(this.domMoved) return
      window.open(process.env.VUE_APP_CHATGPT_URL, '_blank')
    }
   }
}

</script>
<style lang="scss" scoped>
.imgicon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  // 鼠标移入效果
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  overflow: hidden;
  img {
      pointer-events: none;
  }
  p {
    margin: 0;
    position: relative;
    top: -28px;
    left: 13px;
    color: #fff;
    font-size: 14px;
  }
}

</style>

